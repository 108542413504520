
import './home.scss';
import Akili from 'akili';
import router from 'akili/src/services/router';
import store from 'akili/src/services/store';
import config from 'config';
import { getAuthUrlVK, getAuthUrlFB, changeLanguage, logout } from 'actions/user';

export default class Home extends Akili.Component {
  static template = require('./home.html');

  static define() {
    Akili.component('home', this);    

    router.add('app.home', 'home/', {
      component: this,
      handler: transition => {
        if(router.isActiveState('app.home')) {
          return transition.redirect(config.get('indexState'));
        }
      }
    });
  }

  created() {
    this.scope.menu = [];
    this.scope.signInVK = this.signInVK.bind(this);
    this.scope.signInFB = this.signInFB.bind(this);
    this.scope.changeLanguage = this.changeLanguage.bind(this);
    this.scope.logout = this.logout.bind(this);    
  }

  compiled() {    
    this.store('language', 'language');
    this.store('currentCat', 'currentCat');
    this.store('platform', 'platform');     
    this.store('isPlayerVisible', 'isPlayerVisible'); 
    this.store('menu', 'menu');
    this.store('user', 'user');
  }

  signInVK() {
    const ref = window.open(getAuthUrlVK(), window.cordova? '_blank': '_self', 'location=no');

    ref.addEventListener('loadstop', (event) => {
      if(new URL(event.url).hostname == new URL(config.vk.redirectUrl).hostname) {
        ref.close();
        location.reload();
      }
    });
  }

  signInFB() {
    const ref = window.open(getAuthUrlFB(), window.cordova? '_blank': '_self', 'location=no');

    ref.addEventListener('loadstop', (event) => {
      if(new URL(event.url).hostname == new URL(config.fb.redirectUrl).hostname) {
        ref.close();
        location.reload();
      }      
    });
  }

  async changeLanguage(lang) {
    try {
      store.preferredLanguage = lang;

      await Promise.all[
        changeLanguage(lang),
        router.reload({ lang })
      ];
    }
    catch(err) {
      // eslint-disable-next-line no-console
      console.error(err);
      store.event = { isError: true, message: err.message };
    }        
  }

  async logout() {
    try {
      await logout();
      location.reload();
    }
    catch(err) {
      // eslint-disable-next-line no-console
      console.error(err);
      store.event = { isError: true, message: err.message };
    } 
  }
}