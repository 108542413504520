import store from 'akili/src/services/store';

export default function access (level) {
  if(level === undefined) {
    return true;
  }

  if(!store.user || store.user.access < level) {
    return false;
  }

  return true
}