import request from 'akili/src/services/request';

/**
 * Paginate music
 */
export async function paginate (params = {}) {
  return await request.use.api.get('music/paginate', { params }).then(res => res.data);
}

/**
 * Save the song
 */
export async function save (id = '', song) {  
  return await request.use.api.post(`music/${id}`, { json: song }).then(res => res.data);
}

/**
 * Get the player info
 */
export async function getPlayerInfo (id, params) {
  return await request.use.api.get(`music/player/info/${id}`, { params }).then(res => res.data);
}

/**
 * Delete a song
 */
export async function remove (id) {
  return await request.use.api.delete(`music/${id}`).then(res => res.data);
}

/**
 * Toggle playlist of the song
 */
export async function togglePlaylist (id) {
  return await request.use.api.post(`music/playlist/${id}`);
}

/**
 * Toggle rating of the song
 */
export async function toggleRating (id) {
  return await request.use.api.post(`music/rating/${id}`);
}

/**
 * Toggle an acception of the song
 */
export async function toggleAcception (id) {
  return await request.use.api.post(`music/acception/${id}`);
}

/**
 * Toggle publication of the song
 */
export async function togglePublication (id) {
  return await request.use.api.post(`music/publication/${id}`);
}

/**
 * Change songs position in the playlist
 */
export async function changePlaylistPos (from, to) {
  return await request.use.api.post(`music/playlist/pos/${from}/${to}`);
}

/**
 * Change the playlist's colors
 */
export async function changePlaylistColors (playlistId, colors) {
  return await request.use.api.post(`music/playlist/color/${playlistId}`, { json: { colors } });
}
