import { get, set, has } from 'lodash';

export default Object.assign(CONFIG, {
  get(keys) {
    if(!keys) {
      return CONFIG;
    }

    return get(CONFIG, keys);
  },
  set(keys, val) {
    return set(CONFIG, keys, val);
  },
  has(keys) {
    return has(CONFIG, keys);
  }
});