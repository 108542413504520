import request from 'akili/src/services/request';

/**
 * Paginate sources
 */
export async function paginate (options = {}) {
  return await request.use.api.get('sources/paginate', { params: options }).then(res => res.data);
}

/**
 * Save the source
 */
export async function save (id = '', source) {
  return await request.use.api.post(`sources/${id}`, { json: source }).then(res => res.data);
}

/**
 * Delete the source
 */
export async function remove (id) {
  return await request.use.api.delete(`sources/${id}`).then(res => res.data);
}

/**
 * Toggle the source acception
 */
export async function toggleAcception (id) {
  return await request.use.api.post(`sources/acception/${id}`);
}

/**
 * Toggle publication of the source
 */
export async function togglePublication (id) {
  return await request.use.api.post(`sources/publication/${id}`);
}