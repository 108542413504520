import './player.scss';
import Akili from 'akili';
import utils from 'akili/src/utils';
import store from 'akili/src/services/store';
import router from 'akili/src/services/router';
import * as files from 'lib/files';
import config from 'config';
import { toggleRating, togglePlaylist, getPlayerInfo, toggleAcception } from 'actions/music';

/**
 * Player
 */
export default class Player extends Akili.Component {
  static template = require('./player.html');
  static ssr = false;

  static define() {
    Akili.component('player', this);
  }

  created() {
    this.media = null;
    this.mediaList = [];
    this.scope.progress = 0;
    this.scope.buffer = 0;
    this.volume = 0.75;
    this.history = [];
    this.filter = null;
    this.debounce = 100;
    this.audioDelayTimeout = 6000;
    this.scope.isLoading = false;
    this.scope.random = window.workStorage.getItem('playerRandom')? true: false; 
    this.scope.repeat = window.workStorage.getItem('playerRepeat')? true: false;    
    this.scope.togglePlaylist = this.togglePlaylist.bind(this); 
    this.scope.toggleRating = this.toggleRating.bind(this);
    this.scope.toggleRandom = this.toggleRandom.bind(this);
    this.scope.toggleRepeat = this.toggleRepeat.bind(this);
    this.scope.toggleAcception = this.toggleAcception.bind(this);        
    this.scope.setPrevSong = this.setPrevSong.bind(this);    
    this.scope.setNextSong = this.setNextSong.bind(this); 
    this.scope.play = this.play.bind(this);
    this.scope.setVolume = this.setVolume.bind(this);
    this.scope.pause = this.pause.bind(this); 
    this.scope.isTouchDevice = 'ontouchstart' in window;
    this.elFooter = this.el.querySelector('.player-footer'); 
    this.elPlayer = this.el.querySelector('.player');  
    this.elAudio = this.el.querySelector('audio');  
    this.elProgress = this.el.querySelector('.player-progress');
  }

  compiled() {
    this.store('isPlayerVisible', this.calculatePlayerPosition);
    this.store('activeSong', this.changeActiveSong);
    this.store('song', 'song', { get: false });
    this.store('song', this.changeSong);       
    this.store('musicPageFind', this.setInfo, { callOnStart: false });   
    this.elProgress.addEventListener('click', this.setProgress.bind(this));   
    this.elAudio.addEventListener('play', () => this.scope.isPlaying = true);
    this.elAudio.addEventListener('pause', () => this.scope.isPlaying = false);
    this.elAudio.addEventListener('ended', () => this.onMusicEnd());
    this.handlePlayerPosition = utils.debounce(this.calculatePlayerPosition.bind(this), this.debounce);
    this.listenStateChanged = this.onStateChanged.bind(this);
    this.listenBodyKey = this.keyboardControl.bind(this);
    document.body.addEventListener('keydown', this.listenBodyKey);
    window.addEventListener('scroll', this.handlePlayerPosition);
    window.addEventListener('state-changed', this.listenStateChanged);
  }

  removed() {
    window.removeEventListener('scroll', this.handlePlayerPosition);
    window.removeEventListener('state-changed', this.listenStateChanged);
    document.body.removeEventListener('keydown', this.listenBodyKey);
  }

  keyboardControl(event) {
    if(!store.isPlayerVisible) {
      return;
    }

    if(document.activeElement && document.activeElement.matches('input,textarea,[contenteditable]')) {
      return;
    }

    if(event.code == "ArrowRight") {
      return this.setNextSong();
    }

    if(event.code == "ArrowLeft") {
      return this.setPrevSong();
    }

    if(event.code == 'Space') {
      event.stopPropagation();
      event.preventDefault();
      this.scope.isPlaying? this.pause(): this.play();      
    }
  }

  onStateChanged() {  
    this.calculatePlayerPosition(); 
  }

  onMusicEnd() {
    if(this.scope.repeat) {
      this.play()
    }
    else if(this.scope.nextSong) {
      this.setNextSong();
    }
  }

  changeSong(song) {
    if(this.scope.song && this.scope.song.id == song.id) {
      this.scope.song = song;
    }
  }

  setNextSong() {
    if(!this.scope.nextSong) {
      return;
    }

    store.activeSong = this.scope.nextSong;
  }

  setPrevSong() {
    if(!this.scope.prevSong) {
      return;
    }

    this.scope.random && (this.prevIsActive = true);
    store.activeSong = this.scope.prevSong;
  }

  async toggleRandom() {
    this.scope.random = !this.scope.random;
    this.history = this.scope.random && this.scope.song? [this.scope.song]: [];  

    if(this.scope.random) {
      window.workStorage.setItem('playerRandom', 'true');      
    }
    else {
      window.workStorage.removeItem('playerRandom');      
    }    

    await this.setInfo();
  }

  toggleRepeat() {
    this.scope.repeat = !this.scope.repeat;

    if(this.scope.repeat) {
      window.workStorage.setItem('playerRepeat', 'true');      
    }
    else {
      window.workStorage.removeItem('playerRepeat');
    }    
  }

  async setInfo() {
    if(!this.scope.song) {
      return;
    }
    
    let filter = window.workStorage.getItem('musicFilter');
    filter = filter? JSON.parse(filter): {};
    this.scope.random && (filter.sort = 'random');   
    const songId = router.transition.path.params.songId;

    if(filter.show != 'nonactive') {
      if(store.currentCat != 'all') {
        filter.cat = store.cats.find((cat) => cat.name == store.currentCat).id;
      }
  
      songId && (filter.song = songId);
      store.musicPageFind && (filter.find = store.musicPageFind);

      if(this.filter && !utils.compare(filter, this.filter))  {
        this.history = [];
      }
      
      this.filter = filter;
    }

    const info = await getPlayerInfo(this.scope.song.id, this.filter);

    if(!info.next) {
      info.next = (await getPlayerInfo('', this.filter)).next;
    }

    if(!info.prev && this.scope.random && this.history.length) {
      info.prev = this.history[this.history.length - 1];
    }

    this.scope.prevSong = info.prev;
    this.scope.nextSong = info.next;
  }

  async calculatePlayerPosition() {
    if(!store.isPlayerVisible) {
      return;
    }

    const height = this.elPlayer.getBoundingClientRect().height;

    if(document.body.scrollHeight + 1 >= document.body.clientHeight) {
      this.elFooter.style.height = height + 'px';
    }
    else {
      this.elFooter.style.height = 0;
    } 
  }

  async changeActiveSong(song) {
    await this.stopLoading();
    store.isPlayerVisible = !!song; 
    this.scope.song = song;
    this.scope.progress = 0;
    this.scope.buffer = 0;

    if(!song) {
      return;
    }
    
    if(this.scope.random && this.prevIsActive) {
      delete this.prevIsActive;
      this.history.pop();
      this.history.pop();
    }
          
    this.scope.title = song.cleanedTitle;   

    try {
      const result = await Promise.all([
        this.setInfo(),
        this.loadSrc(song)
      ]);

      if(result[1] === false) {
        return;
      }

      this.play();        

      if(this.scope.random && !this.prevIsActive) {
        this.history.push(song);
      }      
    }
    catch(err) {
      //eslint-disable-next-line no-console    
      console.error(err);
      this.showAudioError(); 
      this.failNextSongTimeout = setTimeout(() => this.setNextSong(), 1000);        
    }
  }

  async loadSrc(song) {
    this.scope.isLoading = true;
    let result;

    try {
      result = store.platform == 'browser'? await this.loadSrcBrowser(song): await this.loadSrcMobile(song);
    }
    catch(err) {
      await this.stopLoading(); 
      throw err;
    }

    this.scope.isLoading = false;
    return result;
  }

  async stopLoading() {
    this.scope.audioError = false;
    this.scope.isPlaying = false;
    this.scope.isLoading = false;
    this.failNextSongTimeout && clearTimeout(this.failNextSongTimeout);
    store.platform == 'browser'? await this.stopLoadingBrowser(): await this.stopLoadingMobile();
  }

  async stopLoadingBrowser() {
    this.listenAudioTimeUpdate && this.elAudio.removeEventListener("timeupdate", this.listenAudioTimeUpdate);
    this.listenAudioProgress && this.elAudio.removeEventListener("progress", this.listenAudioProgress);
    this.listenAudioLoadedMetaData && this.elAudio.removeEventListener("loadedmetadata", this.listenAudioLoadedMetaData);
    this.listenAudioError && this.elAudio.removeEventListener("error", this.listenAudioError);
  }

  async stopLoadingMobile() {
    this.isMobileLoaded = false;
    this.media && this.releaseMobileMedia(this.media);
  }

  releaseMobileMedia(media) {
    if(!media || media.__released || media.__releasing) {
      return;
    }

    media.__releasing = true;
    clearInterval(media.__mediaInterval);
    clearTimeout(media.__delayTimeout);
    media.stop();
    delete media.__releasing;
    media.__released = true;
  }

  async loadSrcBrowser(song) {
    await new Promise((resolve, reject) => {
      this.elAudio.src = config.filesServerUrl + song.audioPath;

      this.listenAudioLoadedMetaData = () => {
        const lsPlayerVolume = window.workStorage.getItem('playerVolume');
        this.setVolume(lsPlayerVolume === null? this.volume: lsPlayerVolume);
        this.elAudio.addEventListener("timeupdate", this.listenAudioTimeUpdate);    
        resolve();
      };

      this.listenAudioTimeUpdate = () => {
        this.checkProgress();
        this.checkBufferProgress();
      };

      this.listenAudioError = () => {
        reject(new Error('Audio not found'));
      };
      
      this.elAudio.addEventListener("error", this.listenAudioError); 
      this.elAudio.addEventListener('loadedmetadata', this.listenAudioLoadedMetaData);
    });
  }

  async loadSrcMobile(song) {
    let filename = cordova.file.externalCacheDirectory + 'music-' + song.id + '.mp3';  
    let media;

    if(!await files.exists(filename)) {
      filename = config.filesServerUrl + song.audioPath;
    }    

    const result = await new Promise((resolve, reject) => {      
      media = this.media = new Media(filename, () => {}, (err) => { 
        this.releaseMediaMobile(media);
        err.code !== 0? reject(new Error(err.message || 'Wrong audio file')): resolve(false);
      }, (status) => {
        clearTimeout(this.mobileReleaseTimeout);
        this.mobileReleaseTimeout = setTimeout(() => {
          for(let i = this.mediaList.length - 1; i >= 0; i--) {
            const media = this.mediaList[i];

            if(!media.__released) {
              continue;
            }

            media.release();
            this.mediaList.splice(i, 1);
          }

          if(this.scope.isPlaying && this.media.__released) {
            store.activeSong = song;
          }
          else if(!this.scope.isPlaying && media !== this.media) {
            this.mediaList.push(media);
            this.media.play();
          }
        }, 1000);

        if(media.__releasing || media.__released) {
          status < 3 && media.stop();
          return;
        }

        const prevStatus = this.scope.isPlaying;
        this.scope.isPlaying = status == 2 || status == 1;
        
        if(status == 4 && prevStatus) {
          this.onMusicEnd();
        }

        if(media.__resolved && (status == 2 || status == 3)) {          
          MusicControls.updateIsPlaying(status == 2);
        }
        
        if(status == 2 && !media.__resolved) {
          clearTimeout(media.__delayTimeout);
          resolve(media.__resolved = true);
        }
      });
      this.mediaList.push(media);
      media.__delayTimeout = setTimeout(() => {
        !media.__resolved && reject(new Error('Audio loading timeout'));
      }, this.audioDelayTimeout);
      this.play();
    });

    if(result === false) {
      return result;
    }    
    
    await this.setControls(song);
    this.pause();
    this.isMobileLoaded = true;
    media.__mediaInterval = setInterval(() => this.checkProgress(), 1000);   
    return result;
  }

  async setControls(song) {
    window.cordova && await  this.setControlsMobile(song);
  }

  async setControlsMobile(song) {
    return new Promise((resolve, reject) => {
      let coverUrl = song.imgPath? config.filesServerUrl + song.imgPath: null;
    
      MusicControls.create({
        track: song.song,
        artist: song.artist,
        dismissable: false,
        cover: coverUrl || 'nocover.png',
        hasPrev: true,
        hasNext: true
      }, () => {
        MusicControls.subscribe(action => {
          if(!this.isMobileLoaded) {
            return;
          }
  
          const parsed = JSON.parse(action);
          const message = parsed.message;
  
          if(message == 'music-controls-media-button-play-pause' || message == 'music-controls-toggle-play-pause') {
            this.scope.isPlaying? this.pause(): this.play();
          }
          else if(message == 'music-controls-next' || message == 'music-controls-media-button-next') {
            this.setNextSong();
          }
          else if(message == 'music-controls-previous' || message == 'music-controls-media-button-previous') {
            this.setPrevSong();
          }
          else if(message == 'music-controls-pause' || message == 'music-controls-media-button-pause') {
            this.pause();
          }
          else if(message == 'music-controls-play'  || message == 'music-controls-media-button-play') {
            this.play();
          }
          else if(message == 'music-controls-seek-to') {
            MusicControls.updateElapsed({ elapsed: parsed.position, isPlaying: true });
          }
        });
  
        MusicControls.listen();
        resolve();
      }, reject);   
    });
  }

  play() { 
    if(this.scope.isPlaying) {
      return;
    }

    try {
      store.platform == 'browser'? this.playBrowser(): this.playMobile();
    } 
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err);
      this.showAudioError();
    }  
  }
  
  playBrowser() {
    this.elAudio.play();
  }

  playMobile() {
    this.media.play({ playAudioWhenScreenIsLocked : true });
    MusicControls.updateIsPlaying(true);
  }

  pause() {
    if(!this.scope.isPlaying) {
      return;
    }

    store.platform == 'browser'? this.pauseBrowser(): this.pauseMobile();
  }

  pauseBrowser() {
    this.elAudio.pause();
  }

  pauseMobile() {
    this.media.pause();
    MusicControls.updateIsPlaying(false);
  }

  setVolume(val) {
    this.scope.volume = this.elAudio.volume = val;
    window.workStorage.setItem('playerVolume', val);
  }

  checkProgress() {
    document.title = this.scope.song.cleanedTitle; 
    store.platform == 'browser'? this.checkProgressBrowser(): this.checkProgressMobile();
  }

  checkProgressBrowser() {
    if(this.elAudio.readyState < 4 || isNaN(this.elAudio.duration) || !this.elAudio.duration) {
      return;
    }

    const width = this.getProgressWidth();
    this.scope.progress = width * (this.elAudio.currentTime / this.elAudio.duration); 
  }

  checkProgressMobile() {
    const duration = this.media.getDuration();

    if(!duration) {
      return;
    }

    this.media.getCurrentPosition(
      position => {
        const width = this.getProgressWidth();
        
        if (position > 0) {
          this.scope.progress = width * (position / duration);         
        }
        else {  
          this.scope.progress = 0;
        }
      }
    );  
  }

  checkBufferProgress() {
    if(!this.elAudio.duration || isNaN(this.elAudio.duration)) {
      return;
    }
    
    const width = this.getProgressWidth();    
    const buffer = this.elAudio.buffered.end(0);
    this.scope.buffer = width * (buffer / this.elAudio.duration);
  }

  setProgress(event) {
    if(this.scope.showAudioError) {
      return;
    }

    store.platform == 'browser'? this.setProgressBrowser(event): this.setProgressMobile(event);
  }

  setProgressBrowser(event) {
    if (!this.elAudio.duration) {
      return;
    }

    this.elAudio.currentTime = this.elAudio.duration * (event.x / this.getProgressWidth());
    this.elAudio.paused && this.play();
  }

  setProgressMobile(event) {
    const duration = this.media.getDuration();

    if(!duration) {
      return;
    }

    const currentTime = duration * (event.x / this.getProgressWidth())
    this.media.seekTo(currentTime * 1000);
    this.play(); 
  }

  getProgressWidth() {
    return this.elProgress.offsetWidth
  }

  showAudioError() {
    this.scope.isPlaying = false;
    this.scope.audioError = true;
  }

  async togglePlaylist() {
    try {
      this.scope.disabledPlaylist = true;
      await togglePlaylist(this.scope.song.id);
      this.scope.song.playlistId = !this.scope.song.playlistId;
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err); 
      store.event = { isError: true, message: err.message };      
    } 

    delete this.scope.disabledPlaylist; 
  }

  async toggleRating() {
    try {
      this.scope.disabledRating = true;
      await toggleRating(this.scope.song.id);
      this.scope.song.rated = !this.scope.song.rated;
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err);
      store.event = { isError: true, message: err.message };       
    } 

    delete this.scope.disabledRating; 
  }

  async toggleAcception() {
    try {
      this.scope.disabledAcception = true;
      await toggleAcception(this.scope.song.id);
      this.scope.song.isAccepted = !this.scope.song.isAccepted;
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err); 
      store.event = { isError: true, message: err.message };      
    } 

    delete this.scope.disabledAcception; 
  }
}