export default class QueryFilter {
  constructor(key, getFn, setFn) {
    this.key = key;   
    this.getFn = getFn; 
    this.setFn = setFn;
  }

  get(filter, ...args) {
    if(!filter && this.key) {
      filter = window.workStorage.getItem(this.key);
    }

    filter = this.prepare(filter);

    if(this.getFn) {
      filter = this.getFn(filter, ...args);
    }
    
    return filter;
  }

  set(filter, ...args) {
    filter = this.prepare(filter);

    if(this.setFn) {
      filter = this.setFn(filter, ...args);
    }

    return filter;
  }

  prepare(filter) {
    if(!filter) {
      filter = {};
    }

    if(typeof filter == 'string') {
      filter = JSON.parse(filter);
    }

    return filter; 
  }

  save(filter) {
    if(!Object.keys(filter).length) {
      return window.workStorage.removeItem(this.key);
    }
    
    window.workStorage.setItem(this.key, JSON.stringify(filter));
  }
}