import request from 'akili/src/services/request';
import config from 'config';

/**
 * Get vk.com auth url
 */
export function getAuthUrlVK() {
  const authUrl = config.get('vk.authUrl');
  const authParams = config.get('vk.authParams');
  return authUrl + '?' + request.paramsToQuery(authParams);
}

/**
 * Get fb.com auth url
 */
export function getAuthUrlFB() {
  const authUrl = config.get('fb.authUrl');
  const authParams = config.get('fb.authParams');
  return authUrl + '?' + request.paramsToQuery(authParams);
}

/**
 * Logout
 */
export async function logout() {
  return await request.use.api.get('user/logout').then(res => res.data);
}

/**
 * Ping to check the session
 */
export async function ping() {
  return await request.use.api.get('user/ping').then(res => res.data);
}

/**
 * Change the session language
 */
export async function changeLanguage(lang) {
  return await request.use.api.get(`user/language/${lang}`);
}

/**
 * Change the session category
 */
export async function changeCat(cat = '') {
  return await request.use.api.post(`user/cat/${cat}`);
}