import Akili from 'akili';
import A from 'akili/src/components/a';

export default class Link extends A {
  static matches = A.matches + ',[target=_blank]';

  static define() {
    Akili.component('a', this);
  }

  created() {
    if(this.el.getAttribute('target') == '_blank') {
      this.el.addEventListener('click', (event) => {
        if(window.cordova) {
          window.open(this.el.href, '_system');
          event.preventDefault();
        }
      });
    }    
  }

  compiled() {
    if(!this.el.getAttribute('state') && !this.el.getAttribute('url')) {
      return;
    }

    return super.compiled.apply(this, arguments);
  }
}