import './about.scss'
import Akili from 'akili';
import router from 'akili/src/services/router';
import localization from 'akili-localization';

export default class About extends Akili.Component {
  static template = require('./about.html');

  static define() {
    Akili.component('about', this);    

    router.add('app.home.about', 'about/', {
      component: this      
    });
  }

  created() {
    this.scope.questions = localization.translate('questions');
  }
}