const pathLib = require('path');

export async function exists (path) {
  return await new Promise((resolve) => {
    window.resolveLocalFileSystemURL(path, () => resolve(true), () => resolve(false));
  });
} 

export async function download (url, path) {
  return await new Promise((resolve, reject) => {
    let fileTransfer = new FileTransfer();
    url = encodeURI(url);    
    fileTransfer.download(url, path, resolve, reject, true);
  });
} 

export async function readdir (path) {
  return await new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(path, fileSystem => {
      const reader = fileSystem.createReader();
      reader.readEntries(resolve, reject);
    }, reject);
  });
}

export async function remove (path) {
  return await new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(pathLib.dirname(path), dir => {
      dir.getFile(pathLib.basename(path), { create: false }, fileEntry =>  {
        fileEntry.remove(resolve, reject, resolve);
      });
    });
  });
} 

