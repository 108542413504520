import Akili from 'akili';
import utils from 'akili/src/utils';

/**
 * Component to calculte pagination limit
 */
export default class PagLimit extends Akili.Component {
  static events = ['calculate'];
  static ssr = false;

  static define() {
    Akili.component('pag-limit', this);
  }

  created() {
    this.debounce = 100;
    this.prevLimit = null;
  }
  
  compiled() {
    this._onWindowFn = utils.debounce(this.calculate.bind(this), this.debounce);
    window.addEventListener('resize', this._onWindowFn);
    window.addEventListener('scroll', this._onWindowFn);    
    this.calculate();
  }

  removed() {
    window.removeEventListener('resize', this._onWindowFn);
    window.removeEventListener('scroll', this._onWindowFn);
    this._onWindowFn.removeDebounce();
  }

  calculate() {    
    const elRect = this.el.getBoundingClientRect();
    let devY = elRect.top - window.scrollY;    
    let devX = elRect.left - window.scrollX;
    devY < 0 && (devY = 0);
    devX < 0 && (devX = 0);
    elRect.height = document.documentElement.clientHeight - devY;
    elRect.width = document.documentElement.clientWidth - devX;   
    const maskEl = this.el.querySelector('.pag-limit-mask');
    
    if(!maskEl) {
      throw new Error('No found mask element to calculate pagination count');
    }
  
    const hidden = maskEl.hidden;
    maskEl.hidden = false;
    const maskRect = maskEl.getBoundingClientRect();
    maskEl.hidden = hidden;
  
    if(!maskRect.width || !maskRect.height) {
      throw new Error('Mask element must have width and height greater then 0 to calculate pagination count');
    }
  
    const x = Math.floor(elRect.width / maskRect.width);
    const y = Math.floor(elRect.height / maskRect.height); 
    const limit = (x * y) + x * 2;

    if(limit != this.prevLimit) {
      this.attrs.onCalculate.trigger(limit);
    }    

    this.prevLimit = limit;
  }
}