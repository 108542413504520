
import './sources.scss';
import Akili from 'akili';
import router from 'akili/src/services/router';
import store from 'akili/src/services/store';
import QueryFilter from 'lib/query-filter';
import config from 'config';
import { paginate, remove as removeSource, toggleAcception, togglePublication } from 'actions/sources';

/**
 * Create query filter
 */
const queryFilter = new QueryFilter('sourcesFilter', (filter) => {
  if(!filter.show) {
    filter.show = 'accepted';
  }

  return filter;
});

export default class Sources extends Akili.Component {
  static template = require('./sources.html');

  static define() {
    Akili.component('sources', this);    

    router.add('app.home.sources', 'sources/', {
      component: this,
      title: () => `Museria | Sources`,
      query: {
        filter: args => {          
          const filter = queryFilter.get(args.query.filter);
          return Object.keys(filter).length? JSON.stringify(filter): null;
        }
      }      
    });
  }

  created() {
    this.scope.addFilter = this.addFilter.bind(this);
    this.scope.createQueryFilter = this.createQueryFilter.bind(this); 
    this.scope.removeSource = this.removeSource.bind(this);
    this.scope.toggleAcception = this.toggleAcception.bind(this);
    this.scope.togglePublication = this.togglePublication.bind(this);
    this.scope.getSourceType = this.getSourceType.bind(this);
    this.scope.paginate = this.paginate.bind(this);
    this.scope.addNew = this.addNew.bind(this);
    this.scope.saveSource = this.saveSource.bind(this);   
    this.scope.setPaginationLimit = this.setPaginationLimit.bind(this);  
    this.scope.find = this.find.bind(this);
    this.scope.isPreparing = true;
    this.scope.sources = [];
    this.scope.paginationCount = 0;
    this.paginationOptions = {};
    this.paginationIsDone = false;
    this.scope.addNewModalWindowIsOpen = false;
  }

  async compiled() {    
    this.store('config', 'config');
    
    this.scope.filter = queryFilter.prepare(this.transition.query.filter);  
    queryFilter.save(this.scope.filter);
    this.scope.findValue = this.transition.query.find;

    this.paginationOptions = { 
      offset: 0,
      limit: config.get('api.sources.pagination.robotLimit'),
      ...this.scope.filter
    };

    if(store.currentCat != 'all') {
      this.paginationOptions.cat = store.cats.find((cat) => cat.name == store.currentCat).id;
    }

    this.scope.findValue !== undefined && (this.paginationOptions.find = this.scope.findValue);    
  }

  async resolved() {
    this.scope.isPreparing = false;
    return await this.paginate();
  }

  setPaginationLimit(limit) {  
    this.paginationOptions.limit = limit;
  }

  async paginate() {
    try {
      if(this.scope.paginationCount && this.scope.sources.length >= this.scope.paginationCount) {
        return;
      }
  
      const timeout = setTimeout(() => store.preloader = true, 500);
      this.scope.isLoading = true;
      this.paginationOptions.offset = this.scope.sources.length;
      const result = await paginate(this.paginationOptions);
      clearTimeout(timeout);
      this.addSources(result.sources);  
      this.scope.paginationCount = result.count;
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err); 
      store.event = { isError: true, message: err.message };
    }
    
    store.preloader = false;
    this.scope.isLoading = false;
  }

  addSources(sources) {
    const id = {};
    this.scope.sources.forEach(source => id[source.id] = true);    
    this.scope.sources = this.scope.sources.concat(sources.filter(source => !id[source.id]));
  }

  addNew(source) {
    this.scope.addNewModalWindowIsOpen = true;
    this.scope.formSource = source || null;
  }

  saveSource(source) {
    const index = this.scope.sources.findIndex(s => s.id == source.id);
    this.scope.addNewModalWindowIsOpen = false;
    
    if(index !== -1) {
      this.scope.sources[index] = source;
    }
    else {
      router.reload();
    }
  }

  addFilter(key, value) {    
    router.reload({}, { filter: this.createQueryFilter(key, value), find: null });
  }

  createQueryFilter(key, value) {
    let filter = { ...this.scope.filter };
    filter[key] = value;
    filter = queryFilter.set(filter, key);
    return JSON.stringify(filter);
  }

  getSourceType(name) {
    return store.sourceTypes.find(type => type.name == name);
  }

  async find(value) {
    if(String(this.scope.findValue) == value) {
      return;
    }

    this.scope.findValue == value;
    this.scope.sources = [];
    this.paginationOptions.offset = 0;
    this.paginationOptions.find = value;
    this.paginationIsDone = false;
    const path = this.transition;
    const url = router.createStateUrl(path.state, path.params, { ...path.query, ...{ find: value || null } }, path.hash);
    await this.paginate();
    await router.isolate(() => router.setUrl(url));
  }

  removeSource(source) {
    store.confirm = { 
      onYes: async () => {
        try {
          source._disabledRemoving = true;
          await removeSource(source.id);
          this.scope.sources.splice(this.scope.sources.indexOf(source), 1);
          this.scope.paginationCount--;
        }
        catch(err) {
          //eslint-disable-next-line no-console
          console.error(err);
          store.event = { isError: true, message: err.message };       
        }
    
        delete source._disabledRemoving; 
      }
    };
  }

  async toggleAcception(source) {
    try {
      source._disabledAcception = true;
      await toggleAcception(source.id);
      source.isAccepted = !source.isAccepted;

      if(['accepted', 'wait'].indexOf(this.scope.filter.show) != -1) {
        this.scope.sources.splice(this.scope.sources.indexOf(source), 1);
        this.scope.paginationCount--;
      }
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err);  
      store.event = { isError: true, message: err.message };     
    } 

    delete source._disabledAcception;
  }

  async togglePublication(source) {
    try {
      source._disabledPublication = true;
      await togglePublication(source.id);
      source.isPublished = !source.isPublished;
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err);    
      store.event = { isError: true, message: err.message };   
    } 

    delete source._disabledPublication;
  }
}