
import './app.scss'
import Akili from 'akili';
import router from 'akili/src/services/router';
import store from 'akili/src/services/store';
import config from 'config';
import localization from 'akili-localization';

export default class App extends Akili.Component {
  static template = require('./app.html');

  static define() { 
    Akili.component('app', this);
    
    router.add('app', '^/:cat/:lang/', {
      title: () => `Museria - ${ localization.translate('tagline') }`,
      params: {
        lang: args => args.params.lang || store.preferredLanguage,
        cat: args => args.params.cat || store.preferredCat || 'all'
      },
      handler: transition => {
        localization.setCurrentLocale(transition.path.params.lang);
        store.language = transition.path.params.lang;
        store.currentCat = transition.path.params.cat;

        if(router.isActiveState('app')) {
          return transition.redirect(config.get('indexState'));
        }
      }
    });
  }

  created() {
    this.scope.changeEventActivity = this.changeEventActivity.bind(this);    
    this.scope.changeConfirmActivity = this.changeConfirmActivity.bind(this);
    this.scope.confirmOnYes = this.confirmOnYes.bind(this);
    this.scope.confirmOnNo = this.confirmOnNo.bind(this);
    this.scope.x = 0;
    this.scope.event = { isActive: false };
    this.scope.confirm = { isActive: false };
  }

  compiled() {    
    this.store('isPlayerVisible', 'isPlayerVisible');
    this.store('preloader', 'preloader');
    
    this.store('event', (ev) => {
      if(!ev) {
        return;
      }
      
      if(ev.isError) {
        this.scope.event = { message: ev.message || localization.translate('errors.main'), type: 'error', isActive: true };        
      }
      else {
        this.scope.event = { message: ev.message, type: 'event', isActive: true };
      }
    });

    this.store('confirm', (ev) => {
      if(!ev) {
        return;
      }
      
      this.scope.confirm = { 
        message: ev.message || localization.translate('areYouSure'), 
        isActive: true, 
        onYes: ev.onYes, 
        onNo: ev.onNo 
      };
    });
  }

  changeEventActivity(val) {
    this.scope.event.isActive = val;

    if(!val) {
      delete store.event;
    }
  }

  changeConfirmActivity(val) {
    this.scope.confirm.isActive = val;

    if(!val) {
      delete store.confirm;
    }
  }

  confirmOnYes() {
    this.scope.confirm.onYes && this.scope.confirm.onYes();
  }

  confirmOnNo() {
    this.scope.confirm.onNo && this.scope.confirm.onNo();
  }
}