import './cats.scss'
import Akili from 'akili';
import router from 'akili/src/services/router';
import store from 'akili/src/services/store';
import { changeCat } from 'actions/user';
import config from 'config';

export default class Cats extends Akili.Component {
  static template = require('./cats.html');

  static define() {
    Akili.component('cats', this);    

    router.add('app.cats', 'cats/', {
      component: this      
    });
  }

  created() {
    this.scope.backUrl = this.backUrl.bind(this);
    this.scope.saveCat = this.saveCat.bind(this);
    this.playerVisibility = store.isPlayerVisible;
    store.isPlayerVisible = false;
  }

  compiled() {
    this.store('cats', cats => {
      this.scope.cats = [
        { name: 'all', isActive: true },
        ...cats
      ];
    });
  }

  removed() {
    store.isPlayerVisible = this.playerVisibility;
  }

  async saveCat(cat) {
    try {
      store.preferredCat = cat.name;
      await changeCat(cat.id);
    }
    catch(err) {
      // eslint-disable-next-line no-console
      console.error(err);
      store.event = { isError: true, message: err.message };
    } 
  }

  backUrl(cat) {
    let params = {};
    let query = {};
    let state;
    let hash;

    if(!router.transition.previous) {
      state = config.get('indexState');
    }
    else {
      const prev = router.transition.previous.path;
      state = prev.state;
      params = prev.params;
      query = prev.query;
      hash = prev.hash;
    }

    return router.createStateUrl(state, { ...params, cat }, query, hash);
  }
}