import Akili from 'akili';
import For from 'akili/src/components/for';
import store from 'akili/src/services/store';
import Sortable from '@shopify/draggable/lib/sortable';

/**
 * Playlist
 */
export default class Playlist extends For {
  static events = For.events.concat(['sort']);

  static define() {
    Akili.component('playlist', this);
  }

  constructor(...args) {
    super(...args);

    if(window.AKILI_SSR) {
      return new For(this.el, this.scope);
    }
  }
  
  created() {
    this.sortable = new Sortable(this.el, {
      draggable: 'li.music-playlist-item',
      delay: 300,
      mirror: {
        constrainDimensions: true
      }
    });

    this.sortable.on('sortable:start', event => {
      if(!this.attrs.isActive) {
        store.isMenuEnabled = true;
        event.cancel();        
      }
    });
    
    this.sortable.on('drag:start', () => {
      store.isMenuEnabled = false;
    });

    this.sortable.on('sortable:stop', ({ data: { oldIndex, newIndex } }) => {
      store.isMenuEnabled = true;

      if(oldIndex == newIndex) {
        return;
      }

      this.attrs.onSort.trigger({ oldIndex, newIndex });
    });

    return super.created.apply(this, arguments);
  }

  removed() {
    this.sortable.destroy();
    return super.removed.apply(this, arguments);
  }
}