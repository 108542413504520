import Akili from 'akili';
import tippy from 'tippy.js';

export default class Tooltip extends Akili.Component {
  static define() {
    Akili.component('tooltip', this);
  }

  constructor(...args) {
    super(...args);
    
    if (('ontouchstart' in window) || window.AKILI_SSR) {
      this.cancel();
    }
  }

  created() {    
    this.excludedAttrs = ['is-open', 'class', 'style'];
    this.defaults = { arrow: true, offset: 10, delay: 300 };
    this.options = { ...this.defaults };  
  }

  compiled() { 
    this.attr('options',  options => this.options = { ...this.defaults, ...options });
    this.attr('options', this.create, { callOnStart: false });
    this.attr((val, key) => this.excludedAttrs.indexOf(key) == -1 && this.create(), { callOnStart: false });
    this.create();    
    this.attr('is-open', val => val? this.show(): this.hide()); 
  } 

  removed() { 
    this.tooltip && this.tooltip.destroy();
    this.el.removeAttribute('title');
    this.el.removeAttribute('data-original-title');
  }

  create() {      
    this.removed(); 

    if(this.attrs.message !== undefined) {      
      this.el.setAttribute('title', this.attrs.message); 
    }

    if(this.el.hasAttribute('title')) {    
      this.tooltip = tippy.one(this.el, this.options);
    }
  }

  show() {
    this.tooltip && this.tooltip.show();
  }

  hide() {
    this.tooltip && this.tooltip.popperInstance && this.tooltip.hide();
  }
}