import './source-form.scss'
import Akili from 'akili';
import store from 'akili/src/services/store';
import { save } from 'actions/sources';
import localization from 'akili-localization';

/**
 * Source form to create/edit an instance
 */
export default class SourceForm extends Akili.Component {
  static template = require('./source-form.html');
  static events = ['save'];

  static define() {
    Akili.component('source-form', this);
  }

  created() {
    this.scope.save = this.save.bind(this);
    this.scope.setCat = this.setCat.bind(this);
  }

  compiled() {
    this.attr('source', (source) => {
      this.scope.source = source || { 
        cats: store.currentCat != 'all'? [store.cats.find((cat) => cat.name == store.currentCat).id]: [], 
        isMain: false, 
        type: "vk" 
      };
    }, { callOnStart: true });

    this.store('cats', cats => {
      this.scope.cats = cats.filter(cat => cat);
    });
    
    this.checkCorrection();
  }

  checkCorrection() {
    if(!this.scope.source.cats.length) {
      this.scope.source.cats = [this.scope.cats[0].id];
    }
  }

  setCat(id) {
    const index = this.scope.source.cats.indexOf(id);

    if(index == -1) {
      this.scope.source.cats.push(id);
    }
    else  {
      this.scope.source.cats.splice(index, 1);
    }

    if(!this.scope.source.cats.length) {
      this.scope.source.cats = [id];
    } 
  }

  async save() {
    try {
      const source = await save(this.scope.source.id, this.scope.source);
      store.event = { message: localization.translate('sourceIsSaved') };
      this.attrs.onSave.trigger(source);
    }
    catch(err) {
      let message = err.message;

      if(!err.response) {
        //eslint-disable-next-line no-console
        console.error(err);
        message = err.message;
      }
      else {
        const code = err.response.data.code;
        message = localization.translate(`errors.${code && localization.hasTranslation(`errors.${code}`)? code: 'main'}`);
      }

      store.event = { isError: true, message };
    }
  }
}