import './song-form.scss'
import Akili from 'akili';
import store from 'akili/src/services/store';
import { save } from 'actions/music';
import localization from 'akili-localization';

/**
 * Song form to create/edit an instance
 */
export default class SongForm extends Akili.Component {
  static template = require('./song-form.html');
  static events = ['save'];

  static define() {
    Akili.component('song-form', this);
  }

  created() {
    this.scope.song = {};
    this.scope.save = this.save.bind(this);    
    this.scope.setCat = this.setCat.bind(this);
  }

  compiled() {
    this.attr('song', (song) => {
      this.scope.song = song || { 
        cats: store.currentCat != 'all'? [store.cats.find((cat) => cat.name == store.currentCat).id]: [], 
        title: '',
        isAccepted: true
      };
    }, { callOnStart: true });

    this.store('cats', cats => {
      this.scope.cats = cats.filter(cat => cat);
    });
    
    this.checkCorrection();
  }

  checkCorrection() {
    if(!this.scope.song.cats.length) {
      this.scope.song.cats = [this.scope.cats[0].id];
    }
  }

  setCat(id) {
    const index = this.scope.song.cats.indexOf(id);

    if(index == -1) {
      this.scope.song.cats.push(id);
    }
    else  {
      this.scope.song.cats.splice(index, 1);
    }    

    if(!this.scope.song.cats.length) {
      this.scope.song.cats = [id];
    }  
  }

  async save() {
    try {
      const song = await save(this.scope.song.id, this.scope.song);
      store.event = { message: localization.translate('songIsSaved') };
      this.attrs.onSave.trigger(song);
    }
    catch(err) {
      let message = err.message;

      if(!err.response) {
        //eslint-disable-next-line no-console
        console.error(err);
        message = err.message;
      }
      else {
        const code = err.response.data.code;
        message = localization.translate(`errors.${code && localization.hasTranslation(`errors.${code}`)? code: 'main'}`);
      }

      store.event = { isError: true, message };
    }
  }
}