import './styles/main.scss';
import config from 'config';
import Akili from 'akili';
import globals from 'akili/src/globals';
import router from 'akili/src/services/router';
import request, { Request } from 'akili/src/services/request';
import App from 'controllers/app/app';
import Home from 'controllers/home/home';
import Cats from 'controllers/cats/cats';
import Music from 'controllers/music/music';
import Sources from 'controllers/sources/sources';
import About from 'controllers/about/about';
import PrivacyPolicy from 'controllers/privacy-policy/privacy-policy';
import Menu from 'components/menu/menu';
import Scroller from 'components/scroller/scroller';
import Scrollbar from 'components/scrollbar/scrollbar';
import PagLimit from 'components/pag-limit/pag-limit';
import Tooltip from 'components/tooltip/tooltip';
import ModalWindow from 'components/modal-window/modal-window';
import ColorsModalWindow from './components/colors-modal-window//colors-modal-window';
import SourceForm from 'components/source-form/source-form';
import SongForm from 'components/song-form/song-form';
import Link from 'components/link/link';
import Player from 'components/player/player';
import Playlist from 'components/playlist/playlist';
import store from 'akili/src/services/store';
import access from 'lib/access';
import localization from 'akili-localization';
import russianDictionary from './languages/ru.json';
import englishDictionary from './languages/en.json';
import { ping } from 'actions/user';
import { getCategories } from 'actions/cat';
import { getColors } from 'actions/color';

store.config = config;

/**
 * Prepare the localization service
 */
localization.define({ defaultLocale: 'ru' });
localization.addDict('ru', russianDictionary); 
localization.addDict('en', englishDictionary);
store.language = localization.getDefaultLocale().language;
store.preferredLanguage = store.language;

/**
 * Store the menu items
 */
store.menu = [
  {
    translationKey: 'menu.music',
    state: 'app.home.music'
  },
  {
    translationKey: 'menu.sources',
    state: 'app.home.sources'
  },
  {
    translationKey: 'menu.about',
    state: 'app.home.about'
  }
];

/**
 * Store the source types
 */
store.sourceTypes = [
  {
    name: 'vk',
    brandIcon: 'vk',
    translationKey: 'sourceTypes.vk'
  },
];

/**
 * Add access function to globals
 */
globals.access = access; 

/**
 * Define the components
 */
App.define();
Home.define();
Cats.define();
Sources.define();
Music.define();
About.define();
PrivacyPolicy.define();
Menu.define();
Scroller.define();
PagLimit.define();
Tooltip.define();
Link.define();
Player.define();
Playlist.define();
ModalWindow.define();
ColorsModalWindow.define();
SourceForm.define();
SongForm.define();
Scrollbar.define();

/**
 * Fix mobile device hover style
 */
(() => {
  if('ontouchstart' in document.documentElement) {
    for(let i = document.styleSheets.length - 1; i >= 0; i--) {
      let sheet = document.styleSheets[i];

      if(!sheet.cssRules) {
        return;
      }
      
      for(let k = sheet.cssRules.length - 1; k >= 0; k--) {
        let rule = sheet.cssRules[k];

        if(!rule.selectorText) {
          continue;
        }

        rule.selectorText = rule.selectorText.replace(":hover", ":active");
      }
    }
  }
})();

/**
 * Register api requests instance
 */
request.addInstance('api', new Request(config.get('apiUrl'), { json: true, withCredentials: true }));

/**
 * Set up localStorage and sessionStorage
 */
window.workStorage = sessionStorage;

if(window.cordova) {
  window.workStorage = localStorage;
}
else {
  const storageKeys = ['playerRandom', 'playerRepeat', 'playerVolume',  'musicFilter', 'sourcesFilter'];

  storageKeys.forEach((key) => {
    const local = localStorage.getItem(key);
    const session = sessionStorage.getItem(key);
    local && session === null && sessionStorage.setItem(key, local);
  });

  window.addEventListener('unload', () => {
    storageKeys.forEach((key) => {
      const val = sessionStorage.getItem(key);
      val !== null? localStorage.setItem(key, val): localStorage.removeItem(key, val);
    }); 
  });
}

/**
 * Start the application
 */
document.addEventListener(window.cordova? 'deviceready': 'DOMContentLoaded', async () => { 
  if(window.cordova) {   
    cordova.plugins.backgroundMode.enable(); 
    cordova.plugins.backgroundMode.on('activate', function () {
      cordova.plugins.backgroundMode.disableWebViewOptimizations();
      cordova.plugins.backgroundMode.disableBatteryOptimizations();
    });
    window.open = cordova.InAppBrowser.open;
    store.platform = device.platform;
  }
  else {
    store.platform = 'browser';
  }
 
  window.AKILI_SSR && (document.body.style.visibility = 'hidden');

  /**
   * Handle a mobile backbutton event
   */
  document.addEventListener("backbutton", () => {
    if(router.transition.path.state.name == config.get('indexState')) {
      cordova.plugins.backgroundMode.moveToBackground();
    }
    else {
      navigator.app.backHistory();
    }
  }, false);  
 
  try {
    /**
     * Get the session info
     */
    const data = await ping();      
    store.user = data.user;
    store.preferredLanguage = data.language;
    store.preferredCat = data.cat;
    store.cats = await getCategories();
    store.colors = await getColors();

    /**
     * Inintialize the framework
     */
    router.init('/', location.pathname.match(/\.html$/));
    await Akili.init();    
  }
  catch(err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  document.body.style.visibility = null;
}, false);