import './privacy-policy.scss'
import Akili from 'akili';
import router from 'akili/src/services/router';

export default class PrivacyPolicy extends Akili.Component {
  static template = require('./privacy-policy.html');

  static define() {
    Akili.component('privacy-policy', this);    

    router.add('app.privacy-policy', 'privacy-policy/', {
      component: this      
    });
  }
}