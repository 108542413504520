import './colors-modal-window.scss'
import Akili from 'akili';

/**
 * Colors modal window
 */
export default class ModalWindow extends Akili.Component {
  static template = require('./colors-modal-window.html');
  static events = ['save'];

  static define() {
    Akili.component('colors-modal-window', this);
  }

  created() {
    this.scope.selectColor = this.selectColor.bind(this);
    this.scope.save = this.save.bind(this);
    this.scope.cancel = this.cancel.bind(this);
    this.scope.activeColors = [];
    this.scope.prevActiveColors = [];
    this.scope.colors = [];
  }

  compiled() {
    this.attr('isOpen', 'isOpen');
    this.attr('activeColors', colors => this.scope.prevActiveColors = this.scope.activeColors = colors);
    this.store('colors', colors => this.scope.colors = colors.map(item => ({ id: item.id, color: item.color})));
  }

  selectColor(id) {
    const i = this.scope.activeColors.indexOf(id);
    i == -1? this.scope.activeColors.push(id): this.scope.activeColors.splice(i, 1);
  }

  save() {
    this.child('modal-window').setVisibility(false, false);  
    this.attrs.onSave.trigger(this.scope.activeColors);
  }

  cancel() {
    this.scope.activeColors = this.scope.prevActiveColors;
  }
}