import './music.scss';
import Akili from 'akili';
import router from 'akili/src/services/router';
import store from 'akili/src/services/store';
import utils from 'akili/src/utils';
import localization from 'akili-localization';
import * as files from 'lib/files';
import QueryFilter from 'lib/query-filter';
import config from 'config';
import { 
  paginate, 
  toggleAcception, 
  togglePublication,
  toggleRating, 
  togglePlaylist, 
  changePlaylistPos, 
  changePlaylistColors,
  remove as removeSong 
} from 'actions/music';

/**
 * Create query filter
 */
const queryFilter = new QueryFilter('musicFilter', (filter) => {  
  if(!store.user) {
    delete filter.playlist;
    delete filter.colors;
  }

  if(filter.playlist) {
    delete filter.show;
    delete filter.sort;
    return filter;
  }
  else {
    delete filter.colors;
  }

  if(!filter.show) {
    filter.show = 'accepted';
  }

  if(!filter.sort) {
    filter.sort = 'date';
  }

  return filter;
}, (filter, key) => {
  if(store.user && key == 'playlist' ||  key == 'colors') {   
    filter = { playlist: filter.playlist, colors: filter.colors };
  }
  else {
    delete filter.playlist;        
  }

  return filter;
});

/**
 * Music controller
 */
export default class Music extends Akili.Component {
  static template = require('./music.html');
  
  static define() {
    Akili.component('music', this);

    router.add('app.home.music', 'music/:songId', {
      component: this,
      query: {
        filter: args => {
          if(args.params.songId) {
            return null;
          }
          
          const filter = queryFilter.get(args.query.filter);
          return Object.keys(filter).length? JSON.stringify(filter): null;
        }
      },
    });
  }

  created() {    
    this.scope.toggleCache = this.toggleCache.bind(this);
    this.scope.togglePlaylist = this.togglePlaylist.bind(this);    
    this.scope.toggleRating = this.toggleRating.bind(this);
    this.scope.toggleAcception = this.toggleAcception.bind(this);
    this.scope.togglePublication = this.togglePublication.bind(this);
    this.scope.addFilter = this.addFilter.bind(this);
    this.scope.createQueryFilter = this.createQueryFilter.bind(this);    
    this.scope.addNew = this.addNew.bind(this);
    this.scope.addColors = this.addColors.bind(this);
    this.scope.saveSong = this.saveSong.bind(this); 
    this.scope.saveSongColors = this.saveSongColors.bind(this);
    this.scope.createFileName = this.createFileName.bind(this);
    this.scope.sortPlaylist = this.sortPlaylist.bind(this);     
    this.scope.playSong = this.playSong.bind(this);
    this.scope.createSongStyle = this.createSongStyle.bind(this);   
    this.scope.setPaginationLimit = this.setPaginationLimit.bind(this); 
    this.scope.removeSong = this.removeSong.bind(this);
    this.scope.paginate = this.paginate.bind(this);    
    this.scope.find = this.find.bind(this);
    this.scope.isPreparing = true;
    this.scope.cachedFiles = {};
    this.scope.paginationCount = 0;
    this.scope.songs = [];
    this.scope.currentSong = {};
    this.scope.playlistColors = {};
    this.paginationIsDone = false;    
    this.scope.songId = this.transition.params.songId;      
  }

  async compiled() {
    this.store('config', 'config');
    this.store('platform', 'platform');
    this.store('activeSong', 'activeSong');    
    this.store('musicPageFind', 'findValue', { get: false });
    this.store('song', this.changeSong);
    this.store('colors', this.setColors); 
    
    this.scope.filter = queryFilter.prepare(this.transition.query.filter);  
    queryFilter.save(this.scope.songId? {}: this.scope.filter);
    this.scope.findValue = this.transition.query.find;
        
    this.paginationOptions = { 
      offset: 0,  
      limit: config.get('api.music.pagination.robotLimit'), 
      ...this.scope.filter
    };
    
    if(store.currentCat != 'all') {      
      this.paginationOptions.cat = store.cats.find((cat) => cat.name == store.currentCat).id;
    }

    this.scope.findValue !== undefined && (this.paginationOptions.find = this.scope.findValue);
    this.scope.songId !== undefined && (this.paginationOptions.song = this.scope.songId); 
  }

  async resolved() {
    this.scope.isPreparing = false;
    return await this.paginate();
  }

  setPaginationLimit(limit) {  
    this.paginationOptions.limit = limit;
  }

  async paginate() {
    try {
      if(this.scope.paginationCount && this.scope.songs.length >= this.scope.paginationCount) {
        return;
      }

      const timeout = setTimeout(() => store.preloader = true, 500);      
      this.scope.isLoading = true;
      this.paginationOptions.offset = this.scope.songs.length;
      const result = await paginate(this.paginationOptions);

      if(this.isRemoved) {
        return;
      }

      clearTimeout(timeout);
      this.addSongs(result.songs);
      this.scope.paginationCount = result.count;
        
      if(this.scope.platform != 'browser') {
        const fls = await files.readdir(cordova.file.externalCacheDirectory);
        [].slice.call(fls).forEach(f => this.scope.cachedFiles[f.name] = true);
      }
      
      if(this.scope.songId) {        
        this.scope.currentSong = this.scope.songs.find(s => s.id = this.scope.songId);
        document.title = this.scope.currentSong? this.scope.currentSong.cleanedTitle: `Museria - ${ localization.translate('tagline') }`;
      }
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err); 
      store.event = { isError: true, message: err.message };
    }
    
    store.preloader = false;
    this.scope.isLoading = false;
  }

  setColors(colors) {
    this.scope.colors = colors;
    this.scope.playlistColors = {};
    this.scope.colors.forEach((item) => this.scope.playlistColors[item.id] = item.color);
  }

  addSongs(songs) {
    const id = {};
    this.scope.songs.forEach(song => id[song.id] = true);    
    this.scope.songs = this.scope.songs.concat(songs.filter(song => !id[song.id]));
  }

  createSongStyle(song) {
    return utils.class({
      active: this.scope.activeSong && this.scope.activeSong.id == song.id, 
      failed: song.isFailed,
      unpublished: !song.isPublished,
      inactive: !song.isActive
    });
  }

  addFilter(key, value, isArray) {    
    router.reload({}, { filter: this.createQueryFilter(key, value, isArray), find: null });
  }

  createQueryFilter(key, value, isArray = false) {
    let filter = { ...this.scope.filter };

    if(isArray) {
      if(filter[key]) {
        const i = filter[key].indexOf(value);
        i != -1? filter[key].splice(i, 1): filter[key].push(value);
      }
      else {
        filter[key] = [value];
      }
    }
    else {
      filter[key] = value;
    }
    
    filter = queryFilter.set(filter, key);
    return JSON.stringify(filter);
  }

  addNew(song) {
    this.scope.addNewModalWindowIsOpen = true;
    this.scope.formSong = song || null;
  }

  addColors(song) {
    this.scope.addColorsModalWindowIsOpen = true;
    this.scope.addColorsSong = song || null;
  }

  saveSong(song) {
    const index = this.scope.songs.findIndex(s => s.id == song.id);
    this.scope.addNewModalWindowIsOpen = false;
    this.scope.formSong = null;
    store.song = song;
    
    if(index !== -1) {
      this.scope.songs[index] = song;

      if(
        (['accepted'].indexOf(this.scope.filter.show) != -1 && !song.isAccepted) || 
        (['wait'].indexOf(this.scope.filter.show) != -1 && song.isAccepted) ||
        (['nonactive'].indexOf(this.scope.filter.show) != -1 && song.isActive) ||
        (['nonactive'].indexOf(this.scope.filter.show) == -1 && !song.isActive)
      ) {
        this.scope.songs.splice(index, 1);
        this.scope.paginationCount--;
      }
    }
    else {
      router.reload();
    }
  }

  async saveSongColors(colors) {
    try {
      await changePlaylistColors(this.scope.addColorsSong.playlistId, colors);
      const index = this.scope.songs.findIndex(s => s.id == this.scope.addColorsSong.id);
  
      if(index !== -1) {
        this.scope.songs[index].colors = colors;

        if(this.scope.filter.colors && this.scope.filter.colors.length && !colors.filter(id => this.scope.filter.colors.indexOf(id) != -1).length) {
          this.scope.songs.splice(index, 1);
        }
      }
      else {
        router.reload();
      }    
  
      this.scope.addColorsSong = null;
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err);
      store.event = { isError: true, message: err.message };
    } 
  }

  async find(value) {
    if(String(this.scope.findValue) == value) {
      return;
    }

    this.scope.findValue = value;
    this.scope.songs = [];
    this.paginationOptions.offset = 0;
    this.paginationOptions.find = value;
    this.paginationIsDone = false;
    const path = this.transition;
    const url = router.createStateUrl(path.state, path.params, { ...path.query, ...{ find: value || null } }, path.hash);
    await this.paginate();
    await router.isolate(() => router.setUrl(url));
  }

  async sortPlaylist({ oldIndex, newIndex }) { 
    let o = this.scope.songs[oldIndex];
    let n = this.scope.songs[newIndex];
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          this.scope.songs.splice(newIndex, 0, this.scope.songs.splice(oldIndex, 1)[0]);
          resolve();
        }
        catch(err) {
          reject(err);
        }        
      });
    });
    
    try {
      await changePlaylistPos(n.id, o.id);
      Akili.root.child('player').setInfo();
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err);
      store.event = { isError: true, message: err.message };
    } 
  }

  changeSong(song) {
    this.scope.songs = this.scope.songs.map((item) => item.id == song.id? Object.assign(item, song): item);
  }

  playSong(song) {
    if(window.getSelection().toString()) {
      return false;
    }
    
    this.scope.activeSong = song;
  }

  createFileName(song) {
    return 'music-' + song.id + '.mp3';
  }

  removeSong(song) {
    store.confirm = { 
      onYes: async () => {
        try {
          song._disabledRemoving = true;
          await removeSong(song.id);
          this.scope.songs.splice(this.scope.songs.indexOf(song), 1);
          this.scope.paginationCount--;
        }
        catch(err) {
          //eslint-disable-next-line no-console
          console.error(err);       
          store.event = { isError: true, message: err.message };
        }
    
        delete song._disabledRemoving; 
      } 
    };
  }

  async togglePlaylist(song) {
    try {
      song._disabledPlaylist = true;
      await togglePlaylist(song.id);
      song.playlistId = !song.playlistId;

      if(!song.playlistId && this.scope.filter.playlist) {
        this.scope.songs.splice(this.scope.songs.indexOf(song), 1);
        this.scope.paginationCount--;
      }
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err);  
      store.event = { isError: true, message: err.message };     
    } 

    delete song._disabledPlaylist; 
    store.song = song;
  }

  async toggleRating(song) {
    try {
      song._disabledRating = true;
      await toggleRating(song.id);
      song.rated = !song.rated;
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err);   
      store.event = { isError: true, message: err.message };
    } 

    delete song._disabledRating; 
    store.song = song;
  }

  async toggleAcception(song) {
    try {
      song._disabledAcception = true;
      await toggleAcception(song.id);
      song.isAccepted = !song.isAccepted;

      if(['accepted', 'wait'].indexOf(this.scope.filter.show) != -1) {
        this.scope.songs.splice(this.scope.songs.indexOf(song), 1);
        this.scope.paginationCount--;
      }
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err);    
      store.event = { isError: true, message: err.message };   
    } 

    delete song._disabledAcception; 
    store.song = song;
  }

  async togglePublication(song) {
    try {
      song._disabledPublication = true;
      await togglePublication(song.id);
      song.isPublished = !song.isPublished;
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err);    
      store.event = { isError: true, message: err.message };   
    } 

    delete song._disabledPublication; 
    store.song = song;
  }

  async toggleCache(song) {
    try {
      let filename = this.createFileName(song);
      let path = cordova.file.externalCacheDirectory + filename;
      let url = config.filesServerUrl + song.audioPath;
      song._disabledCache = true;

      if(this.scope.cachedFiles[filename]) {
        await files.remove(path);
        delete this.scope.cachedFiles[filename];
      }
      else {
        await files.download(url, path);
        this.scope.cachedFiles[filename] = true;
      }
      
      delete song._disabledCache;
    }
    catch(err) {
      //eslint-disable-next-line no-console
      console.error(err); 
      store.event = { isError: true, message: err.message };      
    }  

    delete song._disabledCache; 
    store.song = song; 
  }
}